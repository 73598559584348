
import { defineComponent } from "vue";
import {
  sameAs,
  helpers,
  minLength,
  maxLength,
  required,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "vuex";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import InputBox from "@/components/member/InputBox.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import TextButton from "@/components/common/TextButton.vue";
import AlertCircleIcon from "icons/AlertCircle.vue";

const isAllowedChar = helpers.regex(
  //eslint-disable-next-line
  /^[a-zA-Z0-9$@$!%*?&:;<=>%+-.,()~`#"'\[\]^_\|{}\\]*$/
);

const hasSpecialChar = helpers.regex(
  //eslint-disable-next-line
  /(?=.*[$@$!%*?&:;<=>%+-.,()~`#"'\[\]^_|{}\\])/
);

const hasNumber = helpers.regex(/(?=.*[0-9])/);

const hasAlpha = helpers.regex(/(?=.*[a-zA-Z])/);

export default defineComponent({
  name: "SetNewPassword",
  components: {
    BaseHeader2,
    DefaultButton,
    InputBox,
    TextButton,
    AlertCircleIcon,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const store = useStore();
    return {
      store,
      password: "",
      password2: "",
    };
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        isAllowedChar,
        hasNumber,
        hasSpecialChar,
        hasAlpha,
      },
      password2: {
        sameAsPassword: sameAs(this.password),
      },
    };
  },
  methods: {
    resetPassword() {
      const seckey = (this.$route.query as { seckey: string }).seckey;
      if (!this.v$.$invalid) {
        this.$axios
          .post("/member/password/reset", {
            seckey: seckey,
            password: this.password,
            password2: this.password2,
          })
          .then(() => {
            this.$flutter.callHandler("showToast", {
              message: "비밀번호 변경이 완료되었습니다.",
              color: "success",
            });
            this.$router.replace("/");
          });
      }
    },
  },
  created() {
    this.$flutter.callHandler("hideGnb");
  },
});
